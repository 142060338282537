import { gql } from 'apollo-boost';

export default gql`
  query($user_id: ID, $only_my_projects: Boolean, $orderBy: [OrderByClause!]){
    birthdaysAndAnniversaries(orderBy: $orderBy, only_my_projects: $only_my_projects, user_id: $user_id){
      name
      hiring_date
      date_of_birth
      current_age
      time_in_the_company
      next_birthday
      next_work_aniversary
      is_parent
    }
  }
`;
