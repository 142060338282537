<template>
<div class="mb-5 card">
  <div class="card-header d-flex">
    <div class="col p-0">
      <span v-if="!record.id">New Evaluation Cycle</span>
      <span v-if="record.id">{{localRecord.name}}</span>
    </div>
    <div class="col text-end">
      <i class="fas fa-times" @click="$emit('close')"></i>
    </div>
  </div>
  <div class="card-body d-grid">
    <div class="mb-3">
      <div class="company-control">
        <label for="name">Name</label>
        <input v-focus type="text" class="form-control" :class="{'is-invalid': v$.localRecord.name.$invalid}" id="name" v-model="localRecord.name">
          <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-6">
        <label for="start_date">Start date</label>
        <i class="fa-solid fa-calendar"></i>
        <DatePicker :date="localRecord.start_date"
            placeholder="Select Date"
            :css_class="{'is-invalid': v$.localRecord.start_date.$invalid}"
            @change="localRecord = { ...localRecord, start_date: $event }"></DatePicker>
        <div v-for="error of v$.localRecord.start_date.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3 col-md-6" >
        <label for="end_date">End date</label>
        <i class="fa-solid fa-calendar"></i>
        <DatePicker :date="localRecord.end_date"
            placeholder="Select Date"
            :css_class="{'is-invalid': v$.localRecord.end_date.$invalid}"
            @change="localRecord = { ...localRecord, end_date: $event }"></DatePicker>
        <div v-for="error of v$.localRecord.end_date.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
      <i class="fas fa-save"></i>
      Save
    </button>
    <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
      <i class="fas fa-times"></i>
      Cancel
    </button>
  </div>
</div>
</template>

<script>
import Validations from './Validations';
import Blinkable from '../../shared/Blinkable';

export default {
  mixins: [Blinkable, Validations],
  props: ['record'],
  data() {
    return {
      localRecord: structuredClone(this.record),
    };
  },
  methods: {
    save() {
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
};
</script>
