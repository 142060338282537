<style scoped>
  .y-label {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
  .cell {
    height: 20vh;
  }
  .y-axis {
    max-width: 2.5rem;
  }
</style>
<template>
  <div class="container-fluid mb-2">
    <div class="row">
      <div class="col d-flex align-items-center flex-nowrap y-axis">
        <h4 class="y-label fa-rotate-180 sortable m-0 p-0">
          Potential
          <i class="fa-solid fa-right-long mb-1 fa-rotate-90"></i>
        </h4>
      </div>
      <div class="col">
        <div ref="box" class="position-relative drop-zone" @drop="drop_handler" @dragover.prevent @dragenter.prevent>
          <div ref="marker" class="grabbable position-absolute" :draggable="!readOnly" :style="markerStyle">
            <i class="fa-solid fa-circle"></i>
          </div>
          <div class="row">
            <div class="cell col m-2 p-2 bg-company-secondary-ligth d-flex flex-column align-items-center justify-content-center">
              <h4>Potential Gem</h4>
              <p>High Potential / Low Performance</p>
            </div>
            <div class="cell col m-2 p-2 bg-company-primary d-flex flex-column align-items-center justify-content-center">
              <h4>High Potential</h4>
              <p>High Potential / Moderate Performance</p>
            </div>
            <div class="cell col m-2 p-2 bg-company-primary d-flex flex-column align-items-center justify-content-center">
              <h4>Star</h4>
              <p>High Potential / High Performance</p>
            </div>
          </div>
          <div class="row">
            <div class="cell col m-2 p-2 bg-company-third-ligth d-flex flex-column align-items-center justify-content-center">
              <h4>Inconsistent Player</h4>
              <p>Moderate Potential / Low Performance</p>
            </div>
            <div class="cell col m-2 p-2 bg-company-secondary-ligth d-flex flex-column align-items-center justify-content-center">
              <h4>Core Player</h4>
              <p>Moderate Potential / Moderate Performance</p>
            </div>
            <div class="cell col m-2 p-2 bg-company-primary d-flex flex-column align-items-center justify-content-center">
              <h4>High Performer</h4>
              <p>Moderate Potential / High Performance</p>
            </div>
          </div>
          <div class="row">
            <div class="cell col m-2 p-2 bg-company-third-ligth d-flex flex-column align-items-center justify-content-center">
              <h4>Risk</h4>
              <p>Low Potential / Low Performance</p>
            </div>
            <div class="cell col m-2 p-2 bg-company-third-ligth d-flex flex-column align-items-center justify-content-center">
              <h4>Average Performer</h4>
              <p>Low Potential / Moderate Performance</p>
            </div>
            <div class="cell col m-2 p-2 bg-company-secondary-ligth d-flex flex-column align-items-center justify-content-center">
              <h4>Solid Performer</h4>
              <p>Low Potential / High Performance</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <h4>Performance <i class="fa-solid fa-right-long"></i></h4>
    </div>
    Performance: <input class="form-control" type="number" min="0" max="3" step="0.5" :readonly="readOnly" v-model="localRecord.performance" @change="updateMarkerPosition">
    Potential: <input class="form-control" type="number" min="0" max="3" step="0.5" :readonly="readOnly" v-model="localRecord.potential" @change="updateMarkerPosition">
  </div>
</template>
<script>
export default {
  props: ['performance', 'potential', 'readOnly'],
  data() {
    return {
      marker: { x: 0, y: 0 },
    };
  },
  computed: {
    localRecord() {
      return structuredClone({
        performance: this.performance,
        potential: this.potential,
      });
    },
    maxWidth() {
      return this.$refs.box.clientWidth;
    },
    maxHeight() {
      return this.$refs.box.clientHeight;
    },
    markerStyle() {
      return { left: `${this.marker.x}px`, top: `${this.marker.y}px` };
    },
  },
  methods: {
    drop_handler(event) {
      this.localRecord.performance = ((3 * event.layerX) / this.maxWidth).toFixed(2);
      this.localRecord.potential = (this.invertYAxis((3 * event.layerY) / this.maxHeight)).toFixed(2);
      this.marker.x = event.layerX;
      this.marker.y = event.layerY;
      this.$emit('updated', this.localRecord.performance, this.localRecord.potential);
    },
    updateMarkerPosition() {
      this.marker.x = 0;
      this.marker.y = 0;
      const x = (this.localRecord.performance * 100) / 3;
      const y = (this.invertYAxis(this.localRecord.potential) * 100) / 3;
      this.marker.x += this.maxWidth * (x / 100);
      this.marker.y += this.maxHeight * (y / 100);
    },
    invertYAxis(y) {
      const min = 0;
      const max = 3;
      return max - y + min;
    },
  },
  mounted() {
    this.updateMarkerPosition();
  },
};
</script>
