import { gql } from 'apollo-boost';
import {
  PERFORMANCE_EVALUATION_FRAGMENT,
  MY_PERFORMANCE_EVALUATION_FRAGMENT,
} from './fragments';

export const PERFORMANCE_EVALUATION_QUERY = gql`
  query($page: Int,
        $id: ID,
        $user_id: ID,
        $evaluator_id: ID,
        $performance_evaluation_cycle_id: ID,
        $start_date: DateTime,
        $end_date: DateTime,
        $include_forms: Boolean!,
        $orderBy: [OrderByClause!]) {
    performance_evaluations(
      page: $page,
      id: $id,
      user_id: $user_id,
      evaluator_id: $evaluator_id,
      performance_evaluation_cycle_id: $performance_evaluation_cycle_id,
      start_date: $start_date,
      end_date: $end_date,
      orderBy: $orderBy) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...performance_evaluation
      }
    }
  }
  ${PERFORMANCE_EVALUATION_FRAGMENT}
`;

export const MY_PERFORMANCE_EVALUATION_QUERY = gql`
  query($page: Int,
        $id: ID,
        $evaluator_id: ID,
        $start_date: DateTime,
        $end_date: DateTime,
        $include_forms: Boolean!,
        $orderBy: [OrderByClause!]) {
    performance_evaluations: my_performance_evaluations(
      page: $page,
      evaluator_id: $evaluator_id,
      start_date: $start_date,
      end_date: $end_date,
      orderBy: $orderBy) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...my_performance_evaluation
      }
    }
  }
  ${MY_PERFORMANCE_EVALUATION_FRAGMENT}
`;

export const MY_PERFORMANCE_EVALUATION_QUERY_USER = gql`
  query($page: Int,
        $id: ID,
        $evaluator_id: ID,
        $start_date: DateTime,
        $end_date: DateTime,
        $include_forms: Boolean!,
        $orderBy: [OrderByClause!]) {
    performance_evaluations: my_performance_evaluations(
      page: $page,
      evaluator_id: $evaluator_id,
      start_date: $start_date,
      end_date: $end_date,
      orderBy: $orderBy) {
      paginatorInfo {
        hasMorePages
      }
      data {
        id
        status
        user_id
        evaluator_id
        performance_evaluation_cycle_id
        performance_evaluation_form_id
        notes
        created_at
        cycle {
          id
          name
        }
        user {
          id
          name
          avatar
        }
        evaluator {
          id
          name
          avatar
        }
        goals {
          id
          name
          deadline
          is_completed
          notes
        }
        form @include(if: $include_forms) {
          id
          name
          questions {
            id
            order
            name
            answers(performance_evaluation_id: $id) {
              id
              score
            }
          }
        }
      }
    }
  }
`;
