import Vue from 'vue';
import VueRouter from 'vue-router';
import Projects from './components/projects/Index.vue';
import Bots from './components/bots/Index.vue';
import Integrations from './components/integrations/Index.vue';
import Mappings from './components/integrations/mapping/Index.vue';
import ProjectMinutes from './components/project_minutes/Index.vue';
import ProjectRisks from './components/project_risks/Index.vue';
import ProjectHours from './components/projects/Hours.vue';
import Technologies from './components/technologies/Index.vue';
import LearningPaths from './components/learning_paths/Index.vue';
import LearningPathLevels from './components/learning_paths/levels/Index.vue';
import LearningPathNodes from './components/learning_paths/nodes/Index.vue';
import Home from './components/Home.vue';
import Clients from './components/clients/Index.vue';
import Users from './components/users/Index.vue';
import SearchUsers from './components/clients/users/Index.vue';
import Directory from './components/users/directory/Index.vue';
import ProjectRoles from './components/project_roles/Index.vue';
import Buckets from './components/estimation_buckets/Index.vue';
import Estimations from './components/estimations/Index.vue';
import EstimationDetail from './components/estimations/Detail.vue';
import Profile from './components/users/profile/Tab.vue';
import Resume from './components/users/resume/Tab.vue';
import Reports from './components/reports/reports.vue';
import PeopleAllocation from './components/reports/people_allocation/Index.vue';
import PeopleAllocationPerProject from './components/reports/people_allocation_per_project/Index.vue';
import HoursPerProject from './components/reports/hours_per_project/Index.vue';
import Timesheet from './components/reports/timesheet/Index.vue';
import ResumeFullTextSearch from './components/reports/resume_full_text_search/Index.vue';
import Indicators from './components/indicators/Index.vue';
import IndicatorDetail from './components/indicators/Detail.vue';
import ProjectsSummary from './components/reports/projects_summary/Index.vue';
import PromotionsReport from './components/reports/promotions/Index.vue';
import TalentedPeople from './components/reports/talented_people/Index.vue';
import IndicatorComparator from './components/reports/indicator_comparator/Index.vue';
import PeopleInBench from './components/reports/people_in_bench/Index.vue';
import TechnologyDistribution from './components/reports/technology_distribution/Index.vue';
import Timeline from './components/reports/timeline/Index.vue';
import ProjectMetrics from './components/reports/project_metrics/Index.vue';
import ExternalCandidates from './components/external_candidates/Index.vue';
import ExternalCandidateProfile from './components/external_candidates/ExternalCandidateProfile.vue';
import VerticalMarkets from './components/vertical_markets/Index.vue';
import PermissionMatrix from './components/permission_matrix/Index.vue';
import JobTitles from './components/job_titles/Index.vue';
import Promotions from './components/promotions/Index.vue';
import InterviewQuestions from './components/interview_questions/Index.vue';
import Vacation from './components/vacation/Index.vue';
import PeopleVacation from './components/vacation/people_vacation/Index.vue';
import VacationRequests from './components/vacation/vacation_requests/Index.vue';
import MyVacationRequests from './components/vacation/my_vacation_request/Index.vue';
import VacationHistory from './components/vacation/vacation_history/Index.vue';
import Holidays from './components/vacation/holidays/Index.vue';
import Calendar from './components/vacation/calendar/Index.vue';
import Interviews from './components/interviews/Index.vue';
import BusinessUnits from './components/business_units/Index.vue';
import EmbedReports from './components/embed_reports/Index.vue';
import BirthdaysAndAnniversaries from './components/birthdays_and_anniversaries/Index.vue';
import CostPerUser from './components/cost_per_user/Index.vue';
import RatePerUser from './components/rate_per_user/Index.vue';
import MyTime from './components/my_time/Index.vue';
import MyTimeReport from './components/reports/my_time/Index.vue';
import Partners from './components/partners/Index.vue';
import Actions from './components/project_actions/Index.vue';
import ProjectMemberHistory from './components/reports/project_member_history/Index.vue';
import Equipment from './components/equipment/Index.vue';
import TenantCreator from './components/multitenancy/TenantCreator.vue';
import LostGround from './components/multitenancy/LostGround.vue';
import Welcome from './components/multitenancy/Welcome.vue';
import WeeklyForecast from './components/reports/weekly_forecast/Index.vue';
import GrossMarginPerResource from './components/reports/gross_margin_per_resource/Index.vue';
import GateKeeper from './components/gatekeeper/Index.vue';
import PeopleByClient from './components/reports/people_by_client/Index.vue';
import SettingsTab from './components/settings_tab/Tab.vue';
import CompanyDistribution from './components/company_distribution/Index.vue';
import PrivacyPolicy from './components/privacy_policy/Index.vue';
import NetPromoterScore from './components/net_promoter_score/Form.vue';
import ExternalNetPromoterScoreAnswers from './components/net_promoter_score/external/Answers.vue';
import InternalNetPromoterScoreAnswers from './components/net_promoter_score/internal/Answers.vue';
import NetPromoterScoreSurvey from './components/net_promoter_score/Survey.vue';
import ExternalNetPromoterScoreDashboard from './components/net_promoter_score/external/Index.vue';
import InternalNetPromoterScoreDashboard from './components/net_promoter_score/internal/Index.vue';
import Suggestions from './components/suggestions/Index.vue';
import SuggestionsForm from './components/suggestions/Form.vue';
import MyCareer from './components/my_career/Index.vue';
import MyCareerDetails from './components/my_career/Details.vue';
import LearningPathEvaluation from './components/learning_paths/evaluation/Index.vue';
import MarginCalculator from './components/margin_calculator/Index.vue';
import Products from './components/products/Index.vue';
import Badges from './components/badges/Index.vue';
import GcoinTransactions from './components/gcoin_transactions/Index.vue';
import GcoinBalances from './components/gcoin_balances/Index.vue';
import SignatureSettings from './components/signature_settings/Index.vue';
import CostPerPosition from './components/cost_per_position/Index.vue';
import PerformanceEvaluationCycles from './components/performance_evaluation_cycles/Index.vue';
import PerformanceEvaluationForms from './components/performance_evaluation_forms/Index.vue';
import PerformanceEvaluationFormQuestions from './components/performance_evaluation_forms/questions/Index.vue';
import PerformanceEvaluations from './components/performance_evaluations/Index.vue';
import PerformanceEvaluationDetail from './components/performance_evaluations/Detail.vue';
import PerformanceEvaluation9Box from './components/performance_evaluation_9box/Index.vue';
import holidayCalendar from './components/vacation/holiday_calendar/Index.vue';
import TeamHolidays from './components/customer_portal/team_holidays/Index.vue';
import AccountDashboard from './components/reports/account_dashboard/Index.vue';
import RevenueByManager from './components/reports/revenue_by_manager/Index.vue';
import Attachments from './components/attachments/Index.vue';
import Templates from './components/notification_settings/notification_templates/Index.vue';
import RevenueByManagerHistory from './components/reports/revenue_by_manager/Detail.vue';
import Myteams from './components/customer_portal/my_teams/Index.vue';
import Opportunities from './components/opportunities/Index.vue';
import ChangePassword from './components/change_password/Index.vue';
import TurnoverDashboard from './components/reports/turnover_dashboard/Index.vue';
import Contracts from './components/customer_portal/contracts/Index.vue';
import CustomerPortal from './components/customer_portal/CustomerPortal.vue';
import AskGacela from './components/ask_gacela/Index.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/gatekeeper',
      component: GateKeeper,
      name: 'Gatekeeper',
    },
    {
      path: '/change_password',
      component: ChangePassword,
      name: 'change_password',
    },
    {
      path: '/welcome',
      component: Welcome,
      name: 'Welcome',
    },
    {
      path: '/home',
      component: Home,
      name: 'Home',
    },
    {
      path: '/projects',
      component: Projects,
      name: 'Projects',
    },
    {
      path: '/projects/:id/hours',
      name: 'project_hours',
      component: ProjectHours,
    },
    {
      path: '/projects/:id/minutes',
      name: 'project_minutes',
      component: ProjectMinutes,
    },
    {
      path: '/projects/:id/risks',
      name: 'project_risks',
      component: ProjectRisks,
    },
    {
      path: '/projects/:id/attachments',
      name: 'project_attachments',
      component: Attachments,
    },
    {
      path: '/clients',
      component: Clients,
      name: 'Clients',
    },
    {
      path: '/clients/:id/users',
      component: SearchUsers,
      name: 'users_for_client',
    },
    {
      path: '/clients/:id/attachments',
      name: 'client_attachments',
      component: Attachments,
    },
    {
      path: '/technologies',
      component: Technologies,
      name: 'Technologies',
    },
    {
      path: '/interview_questions',
      component: InterviewQuestions,
      name: 'Interview_questions',
    },
    {
      path: '/users',
      component: Users,
      name: 'Users',
    },
    {
      path: '/user_directory',
      component: Directory,
      name: 'User_directory',
    },
    {
      path: '/users/:id/promotions',
      name: 'promotions',
      component: Promotions,
    },
    {
      path: '/users/:id/resume',
      name: 'resume',
      component: Resume,
    },
    {
      path: '/users/:id/interviews',
      name: 'interviews',
      component: Interviews,
    },
    {
      path: '/projects/:id/opportunities',
      name: 'project_opportunities',
      component: Opportunities,
    },
    {
      path: '/clients/:id/opportunities',
      name: 'client_opportunities',
      component: Opportunities,
    },
    {
      path: '/opportunities',
      name: 'opportunities',
      component: Opportunities,
    },
    {
      path: '/users/:id/attachments',
      name: 'user_attachments',
      component: Attachments,
    },
    {
      path: '/project_roles',
      component: ProjectRoles,
      name: 'Project_roles',
    },
    {
      path: '/estimation_buckets',
      component: Buckets,
      name: 'Estimation_buckets',
    },
    {
      path: '/estimations',
      component: Estimations,
      name: 'Estimations',
    },
    {
      path: '/estimations/:id',
      name: 'estimation_detail',
      component: EstimationDetail,
    },
    {
      path: '/profile/:id?',
      component: Profile,
      name: 'profile',
      alias: '/profile',
    },
    {
      path: '/indicators',
      component: Indicators,
      name: 'Indicators',
    },
    {
      path: '/indicators/:id',
      name: 'indicator_detail',
      component: IndicatorDetail,
    },
    {
      path: '/embed_reports',
      component: EmbedReports,
      name: 'Embed Reports',
    },
    {
      path: '/net_promoter_score/external_answers',
      component: ExternalNetPromoterScoreAnswers,
      name: 'External Net Promoter ScoreAnswers',
    },
    {
      path: '/net_promoter_score/internal_answers',
      component: InternalNetPromoterScoreAnswers,
      name: 'Internal Net Promoter Score Answers',
    },
    {
      path: '/net_promoter_score_survey',
      component: NetPromoterScoreSurvey,
      name: 'Net Promoter Score Survey',
    },
    {
      path: '/net_promoter_score/internal_dashboard',
      component: InternalNetPromoterScoreDashboard,
      name: 'Internal Net Promoter Score Dashboard',
    },
    {
      path: '/net_promoter_score/external_dashboard',
      component: ExternalNetPromoterScoreDashboard,
      name: 'External Net Promoter Score Dashboard',
    },
    {
      path: '/my_career',
      component: MyCareer,
      name: 'My Career',
    },
    {
      path: '/my_career/:id',
      name: 'my_career_detail',
      component: MyCareerDetails,
    },
    {
      path: '/reports',
      component: Reports,
      children: [
        { path: 'people_allocation', component: PeopleAllocation, name: 'People Allocation' },
        {
          path: 'people_allocation_per_project',
          component: PeopleAllocationPerProject,
          name: 'People Allocation Per Project',
        },
        { path: 'hours_per_project', component: HoursPerProject, name: 'Hours Per Project' },
        { path: 'timesheet', component: Timesheet, name: 'Timesheet' },
        { path: 'projects_summary', component: ProjectsSummary, name: 'Projects Summary' },
        { path: 'timeline', component: Timeline, name: 'Timeline' },
        { path: 'talented_people', component: TalentedPeople, name: 'Talented People' },
        { path: 'promotions', component: PromotionsReport, name: 'Promotions Report' },
        { path: 'indicator_comparator', component: IndicatorComparator, name: 'Indicator Comparator' },
        { path: 'people_in_bench', component: PeopleInBench, name: 'People In Bench' },
        { path: 'resume_full_text_search', component: ResumeFullTextSearch, name: 'Resume Full Text Search' },
        { path: 'technology_distribution', component: TechnologyDistribution, name: 'Technology Distribution' },
        { path: 'project_metrics', component: ProjectMetrics, name: 'Project Metrics' },
        { path: 'my_time', component: MyTimeReport, name: 'My Time Report' },
        { path: 'project_member_history', component: ProjectMemberHistory, name: 'Project Member History' },
        { path: 'weekly_forecast', component: WeeklyForecast, name: 'Weekly Forecast' },
        { path: 'gross_margin_per_resource', component: GrossMarginPerResource, name: 'Gross Margin Per Resource' },
        { path: 'people_by_client', component: PeopleByClient, name: 'People By Client' },
        { path: 'account_dashboard', component: AccountDashboard, name: 'Account Dashboard' },
        { path: 'revenue_by_manager', component: RevenueByManager, name: 'Revenue By Manager' },
        { path: 'revenue_by_manager/:id', component: RevenueByManagerHistory, name: 'Recurrent Revenue By Manager History' },
        { path: 'turnover_dashboard', component: TurnoverDashboard, name: 'Turnover Dashboard' },
      ],
    },
    {
      path: '/vertical_markets',
      component: VerticalMarkets,
      name: 'Vertical Markets',
    },
    {
      path: '/permission_matrix',
      component: PermissionMatrix,
      name: 'Permission Matrix',
    },
    {
      path: '/job_titles',
      component: JobTitles,
      name: 'Job Titles',
    },

    {
      path: '/vacation',
      component: Vacation,
      children: [
        { path: 'people_vacation', component: PeopleVacation, name: 'People Vacation' },
        { path: 'vacation_history', component: VacationHistory, name: 'Vacation History' },
        { path: 'holidays', component: Holidays, name: 'Holidays' },
        { path: 'vacation_requests', component: VacationRequests, name: 'Vacation Requests' },
        { path: 'my_vacation_requests', component: MyVacationRequests, name: 'My Vacation Requests' },
        { path: 'calendar', component: Calendar, name: 'Calendar' },
        { path: 'holiday_calendar', component: holidayCalendar, name: 'Holiday Calendar' },
      ],
    },
    {
      path: '/bots',
      component: Bots,
      name: 'Bots',
    },
    {
      path: '/integrations',
      component: Integrations,
      name: 'Integrations',
    },
    {
      path: '/integrations/:id/mappings',
      component: Mappings,
      name: 'Mappings',
    },
    {
      path: '/external_candidates',
      component: ExternalCandidates,
      name: 'External Candidates',
    },
    {
      path: '/external_candidates/:id',
      component: ExternalCandidateProfile,
      name: 'External Candidate Profile',
    },
    {
      path: '/business_units',
      component: BusinessUnits,
      name: 'Business Units',
    },
    {
      path: '/birthdays_and_anniversaries',
      component: BirthdaysAndAnniversaries,
      name: 'Birthdays And Anniversaries',
    },
    {
      path: '/cost_per_user',
      component: CostPerUser,
      name: 'Cost Per User',
    },
    {
      path: '/cost_per_position',
      component: CostPerPosition,
      name: 'Cost Per Position',
    },
    {
      path: '/rate_per_user',
      component: RatePerUser,
      name: 'Rate Per User',
    },
    {
      path: '/my_time',
      component: MyTime,
      name: 'My Time',
    },
    {
      path: '/partners',
      component: Partners,
      name: 'Partners',
    },
    {
      path: '/partners/:id/attachments',
      name: 'partner_attachments',
      component: Attachments,
    },
    {
      path: '/projects/:id/actions',
      component: Actions,
      name: 'Actions',
    },
    {
      path: '/equipment',
      component: Equipment,
      name: 'Equipment',
    },
    {
      path: '/register_company',
      component: TenantCreator,
      name: 'Tenant Creator',
    },
    {
      path: '/lostground',
      component: LostGround,

    },
    {
      path: '/net_promoter_score',
      component: NetPromoterScore,
      name: 'Net Promoter Score',
    },
    {
      path: '/suggestions',
      component: Suggestions,
      name: 'Suggestions',
    },
    {
      path: '/suggestions_form',
      component: SuggestionsForm,
      name: 'Suggestions Form',
    },
    {
      path: '/configuration',
      component: SettingsTab,
      name: 'Settings',
    },
    {
      path: '/configuration/:id/notification_templates',
      name: 'notification_templates',
      component: Templates,
    },
    {
      path: '/company_distribution',
      component: CompanyDistribution,
      name: 'Company Distribution',
    },
    {
      path: '/learning_paths',
      component: LearningPaths,
      name: 'Learning Paths',
    },
    {
      path: '/learning_paths/:id/levels',
      name: 'learning_path_levels',
      component: LearningPathLevels,
    },
    {
      path: '/learning_paths/:id/nodes',
      component: LearningPathNodes,
      name: 'Learning Path Nodes',
    },
    {
      path: '/learning_paths/evaluations/:id',
      name: 'learning_path_evaluations',
      component: LearningPathEvaluation,
    },
    {
      path: '/margin_calculator',
      component: MarginCalculator,
      name: 'Margin Calculator',
    },
    {
      path: '/products',
      component: Products,
    },
    {
      path: '/badges',
      component: Badges,
    },
    {
      path: '/gcoin_transactions',
      component: GcoinTransactions,
    },
    {
      path: '/gcoin_balances',
      component: GcoinBalances,
    },
    {
      path: '/privacy_policy',
      component: PrivacyPolicy,
      name: 'Privacy Policy',
    },
    {
      path: '/signature_settings',
      component: SignatureSettings,
      name: 'Signature Settings',
    },
    {
      path: '/performance_evaluation_cycles',
      component: PerformanceEvaluationCycles,
      name: 'Performance Evaluation Cycles',
    },
    {
      path: '/performance_evaluation_forms',
      component: PerformanceEvaluationForms,
      name: 'Performance Evaluation Forms',
    },
    {
      path: '/performance_evaluation_forms/:id',
      component: PerformanceEvaluationFormQuestions,
      name: 'performance_evaluation_form_questions',
    },
    {
      path: '/performance_evaluations',
      component: PerformanceEvaluations,
      name: 'Performance Evaluations',
    },
    {
      path: '/my_performance_evaluations',
      component: PerformanceEvaluations,
      name: 'My Performance Evaluations',
    },
    {
      path: '/performance_evaluation_9box',
      component: PerformanceEvaluation9Box,
      name: 'Performance Evaluation 9Box',
    },
    {
      path: '/performance_evaluations/:id',
      component: PerformanceEvaluationDetail,
      name: 'Performance Evaluation Detail',
    },
    {
      path: '/my_performance_evaluations/:id',
      component: PerformanceEvaluationDetail,
      name: 'My Performance Evaluation Detail',
    },
    {
      path: '/ask_gacela',
      component: AskGacela,
      name: 'Ask Gacela',
    },
    {
      path: '/customer_portal',
      component: CustomerPortal,
      children: [
        { path: 'my_teams', component: Myteams, name: 'My Teams' },
        { path: 'team_holidays', component: TeamHolidays, name: 'Team Holidays' },
        { path: 'contracts', component: Contracts, name: 'Contracts' },
      ],
    },
  ],
});

export default router;
