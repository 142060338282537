<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Birthdays & Anniversaries</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 m-auto mt-2">
              <Search
                :search.sync="search"
                :inputs="['users', 'onlyMyProjectsCheckbox']"
              ></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="birthdaysAndAnniversaries">
      <div class="col-md-12 text-nowrap h-100 scroll-x">
        <table class="table">
          <tr>
            <th>#</th>
            <SortByColumn :sort_by_field="'name'" :orderBy="orderBy" @refresh="refresh">Person</SortByColumn>
            <SortByColumn :sort_by_field="'hiring_date'" :orderBy="orderBy" @refresh="refresh">Hiring Date</SortByColumn>
            <SortByColumn :sort_by_field="'date_of_birth'" :orderBy="orderBy" @refresh="refresh">Date of Birth</SortByColumn>
            <SortByColumn :css_class="'text-center'" :sort_by_field="'birthday_month'" :orderBy="orderBy" @refresh="refresh">Next Birthday</SortByColumn>
            <SortByColumn :css_class="'text-center'" :sort_by_field="'aniversary_month'" :orderBy="orderBy" @refresh="refresh">Next Work Aniversary</SortByColumn>
          </tr>
          <tr :key="index" v-for="(row, index) in birthdaysAndAnniversaries">
            <td>{{index + 1}}</td>
            <td title="Has children">{{row.name}}<i v-if="row.is_parent" class="fa-solid fa-people-roof"></i></td>
            <td>{{$displayDate(row.hiring_date)}} ({{row.time_in_the_company}})</td>
            <td>{{$displayDate(row.date_of_birth)}} ({{row.current_age}})</td>
            <td>
              <span v-if="row.next_birthday">
                <span class="badge bg-company-secondary">
                  <i v-if="isToday(row.next_birthday)" class="fa-solid fa-star text-warning"></i>
                  {{monthNumberToEnglish(row.next_birthday)}}
                </span>
                {{$displayDate(row.next_birthday)}} ({{ remainingDays(row.next_birthday) }})
              </span>
              <i v-else class="fas fa-circle text-danger" :title="`Birthday is not set for ${row.name}`"></i>
            </td>
            <td>
              <span v-if="row.next_work_aniversary">
                <span class="badge bg-company-secondary">
                  <i v-if="isToday(row.next_work_aniversary)" class="fa-solid fa-star text-warning"></i>
                  {{monthNumberToEnglish(row.next_work_aniversary)}}
                </span>
                {{$displayDate(row.next_work_aniversary)}} ({{ remainingDays(row.next_work_aniversary) }})
              </span>
              <i v-else class="fas fa-circle text-danger" :title="`Hiring date is not set for ${row.name}`"></i>
            </td>
          </tr>
          <tr v-if="birthdaysAndAnniversaries && birthdaysAndAnniversaries.length === 0">
            <td class="text-center" colspan="4">No records found</td>
          </tr>
        </table>
      </div>
    </div>
    <Empty v-if="!birthdaysAndAnniversaries?.length" icon="fas fa-birthday-cake"/>
  </div>
</template>

<script>

import moment from 'moment';
import { Search, SortByColumn, SpinnerRouterLink } from '../shared';
import BIRTHDAYS_AND_ANNIVERSARIES_QUERY from './queries';

export default {
  components: { Search, SortByColumn, SpinnerRouterLink },
  data() {
    return {
      orderBy: { column: 'birthday_month', order: 'ASC' },
      search: { only_my_projects: false },
    };
  },
  methods: {
    refresh() {
      this.$apollo.queries.birthdaysAndAnniversaries.refetch();
    },
    isToday(date) {
      return moment(date, 'YYYY-MM-DD').format('MM-DD') === moment().format('MM-DD');
    },
    monthNumberToEnglish(date) {
      return moment(date, 'YYYY-MM-DD').format('MMMM');
    },
    remainingDays(endDate) {
      return moment(endDate, 'YYYY-MM-DD').endOf('day').fromNow();
    },
  },
  apollo: {
    birthdaysAndAnniversaries: {
      query: BIRTHDAYS_AND_ANNIVERSARIES_QUERY,
      errorPolicy: 'all',
      variables() {
        return {
          orderBy: [this.orderBy],
          ...this.search,
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
};
</script>
